<template>
  <div>
    <van-image class="pic_margin" width="100vw" :src="img_travel" />
    <van-row class="pic-next-cont">
      <!--购票功能区域 start-->
      <van-row class="itembk">
        <van-row style="padding: 10px">
          <van-notice-bar left-icon="volume-o" class="blue" background="#ffffff">神州浩天商旅平台提供高校差旅报销一体化解决方案</van-notice-bar>
          <div style="height: 1px;width: 90vw;background-color: #f1f1f1;margin-bottom:5px"></div>
          <div class="box">
            <div class="hezi">
              <!-- 机票 -->
              <div class="shang" @click="$router.push('/buyAircraftView')">
                <p>
                  <button class="button"
                    style="background-image: linear-gradient(to right, white ,white,#cff5fe);">飞机票</button>
                  <van-icon class="iconfont" color="#319afb" class-prefix="icon" name="jichangxiao" />
                </p>
                <h6>特价机票 随心出行</h6>
              </div>
              <!-- 酒店 -->
              <div class="shang" @click="$router.push('/buyHotelView')">
                <p>
                  <button class="button"
                    style="background-image: linear-gradient(to right, white ,white,#fed7da);">享酒店</button>
                  <van-icon class="iconfont" color="#f55c5f" class-prefix="icon" name="jiudian" />
                </p>
                <h6>商旅尊享 雅致安心</h6>
              </div>
            </div>
            <div class="hezi">
              <!-- 火车 -->
              <div class="shang" @click="$router.push('/buyTrainView')">
                <p>
                  <button class="button"
                    style="background-image: linear-gradient(to right, white ,white,#bfffec);">火车票</button>
                  <van-icon class="iconfont" color="#2acac8" class-prefix="icon" name="huochepiaomianxing" />
                </p>
                <h6>闪电出票 畅快预订</h6>
              </div>
            </div>
          </div>
        </van-row>
      </van-row>
    </van-row>

    <!--底部垫底用，对付Tabbar-->
    <van-row type="flex" class="btm" justify="center">
      <van-col>2021 © 天津神州浩天科技有限公司. All Rights Reserved.</van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img_travel: require("../assets/travel.jpg")
    };
  }
};
</script>

<style lang="less" scoped>
@import "../styles/theme.less";
.box {
  width: 100%;
  height: 100%;
}
.hezi {
  width: 100%;
  height: 80px;
  gap: 0.6rem;
  display: grid;
  grid-auto-flow: column dense;
}
p {
  position: relative;
  width: 90%;
  line-height: 8px;
  margin: 11px auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.shang {
  position: relative;
  width: 100%;
  height: 90%;
  background-color: white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
h6 {
  margin: 6px auto;
  position: relative;
  right: -7px;
  color: @gray-6;
}
.button {
  position: relative;
  top: -2px;
  right: 10px;
  border: none;
  border-radius: 20px;
  padding: 3px 15px;
  margin: 3px 10px;
}
.iconfont {
  font-size: 24px;
}
.btm {
  height: 5rem;
  font-size: 12px;
  color: #bfbfbf;
}
</style>